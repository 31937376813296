import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { Divider, Grid } from "@mui/material";
import {
  Btn,
  IconBtnBase,
  ResourcesList,
  setProgress,
} from "Components";
import Yup from "Utils/Yup";
import { makeStyles } from "@mui/styles";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AboutCardDetails from "./Components/AboutCardDetails";
import RefreshIcon from '@mui/icons-material/Refresh';
import { ABOUTDETAIL } from 'Constants/QueriesKeys';
import { useQuery } from "react-query";
import { http } from "Utils/Http/Http";
import { toast } from "react-toastify";
import HeroBox from "Components/HeroBox/HeroBox";


const UPDATE_ABOUT = "UPDATE_ABOUT";

const aboutCardDetailsSchema = Yup.object({
  heading: Yup.string().required("required"),
  description: Yup.string().required("required"),
});

const schema = Yup.object({
  aboutCardDetails: Yup
    .array()
    .of(aboutCardDetailsSchema)
});

const AboutPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [prevDetails, setPrevDetails] = useState()

  const [enabled, setEnabled] = useState(true);
  const aboutPageQuery = useQuery([ABOUTDETAIL], () =>
    http.get(`/api/v1/pages`, {}).then(({ data }) => {
        setPrevDetails(data?.data?.aboutPage);
        setEnabled(false);
      reset({
        aboutCardDetails: data?.data?.aboutPage?.aboutCardDetails,
        headerDetails: data?.data?.aboutPage?.headerDetails
      });
      return data;
    }),
    {
      enabled: enabled ? true : false,
    },
  );
  const form = useForm({
    resolver: yupResolver(schema)
  });
  const { control, handleSubmit, reset, errors } = form;

  const handleFormSubmission = async (data, values) => {

    try {
      await http.post(`/api/v1/pages`, data)
        .then(async (res) => {
          toast.success(t('toast.success.updatedSuccessfully'))
          aboutPageQuery.refetch()
        });
    } catch (res) {
      toast.error(res?.response?.data?.message)
    }
    setProgress(UPDATE_ABOUT, false);
  };

  const headerDataAdd = async (values) => {
    setProgress(UPDATE_ABOUT, true);

    let headerFormData = new FormData();

    var data = {
      aboutPage: values
    }
    if (typeof (values?.headerDetails?.backgroundImage) === "object" && values?.headerDetails?.backgroundImage?.length > 0) {
      headerFormData.append("src", values?.headerDetails?.backgroundImage[0]);
      try {
        await http.post(`/api/v1/helpers/upload-file`, headerFormData)
          .then(async (res) => {
            data['aboutPage'].headerDetails.backgroundImage = res.data.data[0].src
            handleFormSubmission(data, values)
          });
      } catch (res) {
        toast.error(res?.response?.data?.message)
        setProgress(UPDATE_ABOUT, false);
      }
    } else {
      data['aboutPage'].headerDetails.backgroundImage = prevDetails?.headerDetails?.backgroundImage
      handleFormSubmission(data, values)
    }
  };
  return (
    <ResourcesList
      headerProps={{
        title: t('admin.cms.about.aboutMulim'),
        EndSide: (
          <div className='flex'>
            <IconBtnBase
              onClick={() => { aboutPageQuery.refetch() }}
              size="large">
              <RefreshIcon />
            </IconBtnBase>
          </div>
        ),
      }}
      Section={
        <div className="mt-8">
          <Grid container>
            <Grid item xs={12}>
              <form
                onSubmit={handleSubmit(headerDataAdd)}
                className="space-y-3 py-5"
              >
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <h3 className={classes.h4Text}>{t('admin.cms.investorWeek.heroBox')}</h3>
                  </AccordionSummary>
                  <Divider style={{ margin: '10px 0' }} />
                  <AccordionDetails>
                    <HeroBox
                      form={form}
                      control={control}
                      errors={errors}
                      prevHeaderDetails={prevDetails?.headerDetails}
                    />
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <h3 className={classes.h4Text}>{t('admin.cms.about.aboutCardDetails')}</h3>
                  </AccordionSummary>
                  <Divider style={{ margin: '10px 0' }} />
                  <AccordionDetails>
                    <AboutCardDetails
                      form={form}
                      control={control}
                      errors={errors}
                    />
                  </AccordionDetails>
                </Accordion>
                <div className="flex pt-3">
                  <Btn fullWidth={false} type="submit" name={UPDATE_ABOUT}>
                    {t("common.submit")}
                  </Btn>
                </div>
              </form>
            </Grid>
          </Grid>
        </div>
      }
    />
  )
}

export default AboutPage

const useStyles = makeStyles((theme) => ({
  h3Text: {
    textTransform: 'capitalize',
    fontSize: '24px',
    color: 'rgb(3, 166, 90)',
    fontWeight: '500',
  },
  h4Text: {
    textTransform: 'capitalize',
    fontSize: '18px',
    color: 'rgb(3, 166, 90)',
    fontWeight: '500',
  },
  btn_delete: {
    fontSize: "12px",
    textTransform: "capitalize",
    color: "rgba(255,0,0,0.5)",
    margin: "0 20px",
    '&:hover': {
      color: 'rgba(255,0,0,0.7)',
    }
  },
}))