import { Paper, Grid, Box, Menu, MenuItem, Badge } from "@mui/material";
import { IconBtnBase, DataGrid, ResourcesList } from "Components";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { http } from "Utils/Http/Http";
import RefreshIcon from "@mui/icons-material/Refresh";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import { PARTICIPATIONLIST } from "Constants/QueriesKeys";
import { useHistory } from "react-router-dom";
import MoreIcon from "@mui/icons-material/More";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

const styles = {
  tableSize: {
    height: 650,
    width: "100%",
  },
};

const ParticipationsPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [participationList, setParticipationList] = useState([]);
  // const [searchPaticipation, setSearchPaticipation] = useState("")
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState();
  const spacing = 4;
  const [paginator1, setPaginator1] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [version, setVersion] = useState(3);
  const [awardParticipatCategories, setAwardParticipatCategories] =
    useState(null);

  const handleClose = () => setAnchorEl(null);

  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const participationListRender = useQuery(
    [PARTICIPATIONLIST, { page, version, awardParticipatCategories }],
    () =>
      http
        .get(`https://api.mulimgcc.org/api/v1/admin/participations`, {
          params: {
            page: page,
            limit: perPage,
            version,
            awardParticipatCategories,
          },
        })
        .then((response) => {
          return (
            (response,
            setParticipationList(response.data.data),
            setPaginator1(response?.data?.meta?.total)),
            setPerPage(response?.data?.meta?.per_page)
          );
        })
  );

  const participationCount = useQuery(
    [PARTICIPATIONLIST, version],
    () =>
      http
        .get(
          `https://api.mulimgcc.org/api/v1/admin/participations/participations-count`,
          {
            params: {
              version,
            },
          }
        )
        .then((response) => response.data.data),
    {
      keepPreviousData: true,
    }
  );

  const handleDownload = (track) => {
    const link = document.createElement("a");
    link.target = "_blank";
    link.download = `${track}.xlsx`;
    http
      .get(
        `https://api.mulimgcc.org/api/v1/admin/exports/participations?track=${track}&version=${version}`,
        {
          responseType: "blob",
        }
      )
      .then((res) => {
        link.href = URL.createObjectURL(
          new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        link.click();
      });
  };

  function getFullName(params) {
    return `${params.getValue(params.id, "first_name") || ""}
     ${
       params.getValue(params.id, "last_name") === "NO DATA"
         ? ""
         : params.getValue(params.id, "last_name")
     }`;
  }

  const columns1 = [
    {
      field: "id",
      headerName: t("ID"),
      width: 100,
      editable: false,
    },

    {
      field: "fullName",
      headerName: "Full name",
      width: 230,
      valueGetter: getFullName,
      sortComparator: (first_name, last_name, cellParams1, cellParams2) =>
        getFullName(cellParams1).localeCompare(getFullName(cellParams2)),
    },

    {
      field: "email",
      headerName: t("Email"),
      width: 250,
      editable: false,
    },
    {
      field: "track",
      headerName: t("Track"),
      width: 120,
      editable: false,
    },
    {
      field: "awardParticipatCategories",
      headerName: t("Award Category"),
      width: 150,
      editable: false,
    },
    {
      field: "country",
      headerName: t("Country"),
      width: 180,
      editable: false,
    },
    {
      field: "created_at",
      headerName: t("Date"),
      width: 120,
      editable: false,
      renderCell: (row) => row.row.created_at.split(" ")[0],
    },
    {
      field: "action",
      headerName: t("common.actions"),
      width: 100,
      editable: false,
      renderCell: (row) => {
        return (
          <div className="flex items-center">
            <IconBtnBase
              onClick={() =>
                history.push({
                  pathname: `participations/${row.id}`,
                  state: { detailsData: row?.row },
                })
              }
              size="large"
            >
              {/* <ExpandMoreIcon /> */}
              <MoreIcon />
            </IconBtnBase>
          </div>
        );
      },
    },
  ];

  return (
    <ResourcesList
      headerProps={{
        title: t("participations"),
        EndSide: (
          <div className="flex">
            <IconBtnBase onClick={handleClickMenu} size="large">
              <CloudDownloadOutlinedIcon />
            </IconBtnBase>
            <Menu
              id="lock-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "lock-button",
                role: "listbox",
              }}
            >
              <MenuItem
                onClick={() => {
                  handleDownload("video");
                }}
              >
                Download Video
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleDownload("photo");
                }}
              >
                Download Photo
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleDownload("drawing");
                }}
              >
                Download Drawing
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleDownload("writing");
                }}
              >
                Download Writing
              </MenuItem>
            </Menu>
            <IconBtnBase
              onClick={() => {
                console.log(participationListRender.refetch());
              }}
              size="large"
            >
              <RefreshIcon />
            </IconBtnBase>
          </div>
        ),
      }}
      Section={
        <div className="mt-8">
          <Tabs
            value={version}
            onChange={(e, newValue) => {
              setVersion(newValue);
              setPage(1);
            }}
            aria-label="basic tabs example"
            centered
          >
            <Tab label="2024" value={3} />
            <Tab label="2023" value={2} />
            <Tab label="2022" value={1} />
          </Tabs>
          <Tabs
            value={awardParticipatCategories}
            onChange={(e, newValue) => {
              setAwardParticipatCategories(newValue);
              setPage(1);
            }}
            aria-label="basic tabs example"
            centered
          >
            <Tab
              label="All"
              value={null}
              icon={
                <span
                  style={{
                    backgroundColor: "rgb(229, 252, 242)",
                    // backgroundColor: "red",
                    borderRadius: 99,
                    padding: "3px 15px",
                  }}
                >
                  {participationCount.data?.all ?? "--"}
                </span>
              }
              iconPosition="bottom"
            />
            <Tab
              label="Individuals"
              value={"professional"}
              icon={
                <span
                  style={{
                    backgroundColor: "rgb(229, 252, 242)",
                    // backgroundColor: "red",
                    borderRadius: 99,
                    padding: "3px 15px",
                  }}
                >
                  {participationCount.data?.professional ?? "--"}
                </span>
              }
              iconPosition="bottom"
            />
            <Tab
              label="Universities"
              value={"university"}
              icon={
                <span
                  style={{
                    backgroundColor: "rgb(229, 252, 242)",
                    // backgroundColor: "red",
                    borderRadius: 99,
                    padding: "3px 15px",
                  }}
                >
                  {participationCount.data?.university ?? "--"}
                </span>
              }
              iconPosition="bottom"
            />
            <Tab
              label="Schools"
              value={"school"}
              icon={
                <span
                  style={{
                    backgroundColor: "rgb(229, 252, 242)",
                    // backgroundColor: "red",
                    borderRadius: 99,
                    padding: "3px 15px",
                  }}
                >
                  {participationCount.data?.school ?? "--"}
                </span>
              }
              iconPosition="bottom"
            />
          </Tabs>
          <Box className="mt-8" sx={styles.tableSize}>
            <Grid container spacing={spacing}>
              <Grid item xs={12}>
                <Paper>
                  <DataGrid
                    rowCount={paginator1}
                    pagination
                    paginationMode="server"
                    onPageChange={(newPage) => setPage(newPage + 1)}
                    loading={participationListRender?.isLoading}
                    rows={participationList}
                    columns={columns1}
                    pageSize={perPage}
                  />
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </div>
      }
    />
  );
};
export default ParticipationsPage;
