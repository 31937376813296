import React, { useState } from 'react'
import { Grid, MenuItem } from '@mui/material';
import { InputController } from 'Components';
import ImageController from 'Components/FormControls/Controllers/ImageController';
import ImageViewModal from 'Components/ImageViewModal/ImageViewModal';
import { useTranslation } from 'react-i18next';

const HeroBox = ({ control, prevHeaderDetails, form, errors }) => {
    // console.log(prevHeaderDetails);
    const { t } = useTranslation();
    const [modalOpen, setModalOpen] = useState(false);
    const [prevImage, setPrevImage] = useState();
    const flexArray = [
        {
            title: 'flex-start',
            value: 'flex-start',
        },
        {
            title: 'center',
            value: 'center',
        },
        {
            title: 'flex-end',
            value: 'flex-end',
        },
    ];
    return (
        <Grid container spacing={3} style={{ marginBottom: "25px" }}>
            <Grid item xs={12}>
                <Grid container alignItems="center">
                    <Grid item xs={12} md={5} lg={4} xl={3}>
                        <h6
                            className="labelForm"
                            style={{ marginBottom: "0px" }}
                        >
                            {t("admin.cms.home.banner.headingText")}
                        </h6>
                    </Grid>
                    <Grid item xs={12} md={7} lg={8} xl={9} style={{ display: "flex" }}>
                        <InputController
                            size="small"
                            control={control}
                            name={`headerDetails.heading`}
                        />
                        <InputController
                            size="small"
                            type="color"
                            control={control}
                            defaultValue="#000000"
                            style={{ width: "50px", marginRight: "10px" }}
                            fullWidth={false}
                            name={`headerDetails.headingColor`}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container alignItems="center">
                    <Grid item xs={12} md={5} lg={4} xl={3}>
                        <h6
                            className="labelForm"
                            style={{ marginBottom: "0px" }}
                        >
                            {t("admin.cms.home.banner.headerAlign")}
                        </h6>
                    </Grid>
                    <Grid item xs={12} md={7} lg={8} xl={9}>
                        <InputController
                            size="small"
                            select
                            control={control}
                            name={`headerDetails.headerAlign`}
                        >
                            {flexArray.map((option) => (
                                <MenuItem value={option.value}>{option.title}</MenuItem>
                            ))}
                        </InputController>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container alignItems="center">
                    <Grid item xs={12} md={5} lg={4} xl={3}>
                        <h6
                            className="labelForm"
                            style={{ marginBottom: "0px" }}
                        >
                            {t("admin.cms.home.banner.subheadingText")}
                        </h6>
                    </Grid>
                    <Grid item xs={12} md={7} lg={8} xl={9} style={{ display: "flex" }}>
                        <InputController
                            size="small"
                            control={control}
                            name={`headerDetails.subheading`}
                        />
                        <InputController
                            size="small"
                            type="color"
                            control={control}
                            defaultValue="#000000"
                            style={{ width: "50px", marginRight: "10px" }}
                            fullWidth={false}
                            name={`headerDetails.subheadingColor`}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container alignItems="center">
                    <Grid item xs={12} md={5} lg={4} xl={3}>
                        <h6
                            className="labelForm"
                            style={{ marginBottom: "0px" }}
                        >
                            {t("admin.cms.home.banner.subheadingAlign")}
                        </h6>
                    </Grid>
                    <Grid item xs={12} md={7} lg={8} xl={9}>
                        <InputController
                            size="small"
                            select
                            control={control}
                            name={`headerDetails.subheadingAlign`}
                        >
                            {flexArray.map((option) => (
                                <MenuItem value={option.value}>{option.title}</MenuItem>
                            ))}
                        </InputController>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container >
                    <Grid item xs={12} md={5} lg={4} xl={3}>
                        <h6
                            className="labelForm"
                            style={{ marginBottom: "0px" }}
                        >
                            {t("admin.cms.home.banner.backgroundImage")}
                        </h6>
                        {prevHeaderDetails && prevHeaderDetails?.backgroundImage ?
                            <div style={{ padding: "20px" }}>
                                <img src={prevHeaderDetails?.backgroundImage} alt="prev image" style={{ cursor: "pointer" }} onClick={() => { setModalOpen(true); setPrevImage(prevHeaderDetails?.backgroundImage) }} />
                            </div>
                            : null
                        }
                    </Grid>
                    <Grid item xs={12} md={7} lg={8} xl={9}>
                        <ImageController
                            name={`headerDetails.backgroundImage`}
                            filesLimit={1}
                            acceptedFiles
                            form={form}
                            errors={errors}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <ImageViewModal open={modalOpen} handleClose={() => setModalOpen(false)} item={prevImage} />
        </Grid>
    )
}

export default HeroBox