import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import companyLogo from 'Assets/companyLogo.svg';
import { Card, CardContent, Typography, Divider, Container, Grid, InputAdornment, SvgIcon } from '@mui/material';
import { http } from 'Utils/Http/Http';
import yup from 'Utils/Yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Btn, InputController, setProgress } from 'Components';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

const RESET_LINK_BTN = 'RESET_LINK_BTN';

const schema = yup.object().shape({
    password: yup.string().required(),
    password_confirmation: yup.string().required().oneOf([yup.ref('password')], 'Password and Confirm Password do not match.'),
    // password: yup.string().matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/, 'passwordRule'),
    // password_confirmation: yup.string().matches(
    //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
    //     'passwordRule',
    // ).oneOf([yup.ref('password')], 'Password and Confirm Password do not match.'),
});


export default function ResetPasswordPage(props) {
    const { t } = useTranslation();
    const history = useHistory();
    const { match } = props;
    const { params } = match;
    const [successfully, setSuccessfully] = useState(false)
    const [passwordType, setPasswordType] = useState('password')
    const [confirmType, setConfirmType] = useState('password')
    const togglePasswordType = (val) => {
        if (val === 'password') {
            if (passwordType === 'password') {
                setPasswordType('text')
            } else if (passwordType === 'text') {
                setPasswordType('password')
            }
        } else if (val === 'confirm') {
            if (confirmType === 'password') {
                setConfirmType('text')
            } else if (confirmType === 'text') {
                setConfirmType('password')
            }
        }
    }

    const { control, handleSubmit, setError } = useForm({
        resolver: yupResolver(schema),
    });

    const handleForgotPassword = (values) => {
        const data = {
            email: params.emailId,
            resetPasswordToken: params.token,
            password: values.password
        }
        setProgress(RESET_LINK_BTN, true);
        http.post('/api/v1/admin/session/reset-password', data, {
            baseURL: process.env.REACT_APP_BASE_URL,
        })
            .then(async (res) => {
                setSuccessfully(true)
                setTimeout(() => {
                    history.push('/sign-in');
                }, 3000);
            }).catch((res) => {
                debugger
                toast.error(res.message)
            })
        setProgress(RESET_LINK_BTN, false);
    }

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col justify-center items-center px-2 md:px-0">
            <img className="block w-auto" src={companyLogo} alt="CX" style={{ height: "40px", marginBottom: "10px" }} />
            {successfully ?
                <Container className="max-w-md">
                    <Card style={{ padding: '25px 20px', borderRadius: '20px', boxShadow: '1px 1px 10px 1px rgba(21,124,15,0.4)' }}>
                        <Grid container style={{ marginTop: "10px", display: 'flex', justifyContent: 'center', flexDirection: "column" }} spacing={2}>
                            <h6 style={{ textAlign: 'center' }}>Password Changed Successfully.</h6>
                            <h6 style={{ textAlign: 'center' }}>Redirecting...</h6>
                        </Grid>
                    </Card>
                </Container>
                :
                <Container className="max-w-md">
                    <Card style={{ padding: '25px 20px', borderRadius: '20px', boxShadow: '1px 1px 10px 1px rgba(21,124,15,0.4)' }}>
                        <div className="px-5 py-5">
                            <Typography variant="h5">{t('password.resetPassword')}</Typography>
                        </div>
                        <Divider />
                        <CardContent>
                            <div className="space-y-4">
                                {/* <ResetPassword params={params} resetPassword={resetPassword} buttonClassName="flex justify-end mt-5" /> */}
                                <form onSubmit={handleSubmit(handleForgotPassword)}>
                                    <div className="space-y-4">
                                        <div className="space-y-3.5">
                                            <InputController
                                                control={control}
                                                name="password"
                                                label={t('password.newPassword')}
                                                type={passwordType}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <SvgIcon fontSize="small" color="action" onClick={() => togglePasswordType('password')} style={{ cursor: 'pointer' }}>
                                                                {passwordType === 'password' ?
                                                                    <VisibilityOutlinedIcon />
                                                                    :
                                                                    <VisibilityOffOutlinedIcon />
                                                                }
                                                            </SvgIcon>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                            <InputController
                                                control={control}
                                                name="password_confirmation"
                                                label={t('password.confirmationPass')}
                                                type={confirmType}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <SvgIcon fontSize="small" color="action" onClick={() => togglePasswordType('confirm')} style={{ cursor: 'pointer' }}>
                                                                {confirmType === 'password' ?
                                                                    <VisibilityOutlinedIcon />
                                                                    :
                                                                    <VisibilityOffOutlinedIcon />
                                                                }
                                                            </SvgIcon>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex justify-end mt-5">
                                        <Btn type="submit" name={RESET_LINK_BTN} fullWidth={true}>
                                            {t('common.update')}
                                        </Btn>
                                    </div>
                                </form>
                            </div>
                        </CardContent>
                    </Card>
                </Container>
            }
        </div>
    );
}
