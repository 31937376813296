import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { Divider, Grid } from "@mui/material";
import {
  Btn,
  IconBtnBase,
  ResourcesList,
  setProgress,
} from "Components";
import Yup from "Utils/Yup";
import { makeStyles } from "@mui/styles";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import RefreshIcon from '@mui/icons-material/Refresh';
import { HOMEDETAIL } from 'Constants/QueriesKeys';
import { useQuery } from "react-query";
import { http } from "Utils/Http/Http";
import { toast } from "react-toastify";
import BasicDetails from "./Components/BasicDetails";
import ContestTerms from "./Components/ContestTerms";
import AcordianDetails from "./Components/AcordianDetails";
import HeroBox from "Components/HeroBox/HeroBox";


const UPDATE_HOME = "UPDATE_HOME";



const schema = Yup.object({

});
const useStyles = makeStyles((theme) => ({
  h3Text: {
    textTransform: 'capitalize',
    fontSize: '24px',
    color: 'rgb(3, 166, 90)',
    fontWeight: '500',
  },
  h4Text: {
    textTransform: 'capitalize',
    fontSize: '18px',
    color: 'rgb(3, 166, 90)',
    fontWeight: '500',
  },
  btn_delete: {
    fontSize: "12px",
    textTransform: "capitalize",
    color: "rgba(255,0,0,0.5)",
    margin: "0 20px",
    '&:hover': {
      color: 'rgba(255,0,0,0.7)',
    }
  },
}))
const InvestorAward = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [enabled, setEnabled] = useState(true)
  const [prevFileDetails, setPrevFileDetails] = useState()
  const [prevDetails, setPrevDetails] = useState()
  const homePageQuery = useQuery([HOMEDETAIL], () =>
    http.get(`/api/v1/pages`, {}).then(({ data }) => {
      setEnabled(false);
      return (
        data,
        setPrevDetails(data?.data?.gulfSmartInvestorAward),
        reset({
          headerDetails: data?.data?.gulfSmartInvestorAward?.headerDetails,
          aboutAward: data?.data?.gulfSmartInvestorAward?.aboutAward,
          targetedAudience: data?.data?.gulfSmartInvestorAward?.targetedAudience,
          videography: data?.data?.gulfSmartInvestorAward?.videography,
          photography: data?.data?.gulfSmartInvestorAward?.photography,
          drawing: data?.data?.gulfSmartInvestorAward?.drawing,
          accordianDetails: data?.data?.gulfSmartInvestorAward?.accordianDetails
        })
      )
    }),
    {
      enabled: enabled ? true : false,
    },
  );
  const form = useForm({
    resolver: yupResolver(schema)
  });
  const { control, handleSubmit, reset, errors } = form;

  const handleFormSubmit = async (data) => {
    try {
      await http.post(`/api/v1/pages`, data)
        .then(async (res) => {
          toast.success(t('toast.success.updatedSuccessfully'))
          homePageQuery.refetch()
        });
    } catch (res) {
      toast.error(res?.response?.data?.message)
    }
    setProgress(UPDATE_HOME, false);
  }

  const headerDataAdd = async (values) => {
    setProgress(UPDATE_HOME, true);

    let headerFormData = new FormData();

    var data = {
      gulfSmartInvestorAward: values
    }
    if (typeof (values?.headerDetails?.backgroundImage) === "object" && values?.headerDetails?.backgroundImage?.length > 0) {
      headerFormData.append("src", values?.headerDetails?.backgroundImage[0]);
      try {
        await http.post(`/api/v1/helpers/upload-file`, headerFormData)
          .then(async (res) => {
            data['gulfSmartInvestorAward'].headerDetails.backgroundImage = res.data.data[0].src
            handleFormSubmit(data)
          });
      } catch (res) {
        toast.error(res?.response?.data?.message)
        setProgress(UPDATE_HOME, false);
      }
    } else {
      data['gulfSmartInvestorAward'].headerDetails.backgroundImage = prevDetails?.headerDetails?.backgroundImage
      handleFormSubmit(data)
    }
  };
  return (
    <ResourcesList
      headerProps={{
        title: t('admin.cms.investorAward.khalijiInvestorAward'),
        EndSide: (
          <div className='flex'>
            <IconBtnBase
              onClick={() => { homePageQuery.refetch() }}
              size="large">
              <RefreshIcon />
            </IconBtnBase>
          </div>
        ),
      }}
      Section={
        <div className="mt-8">
          <Grid container>
            <Grid item xs={12}>
              <form
                onSubmit={handleSubmit(headerDataAdd)}
                className="space-y-3 py-5"
              >
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <h3 className={classes.h4Text}>{t('admin.cms.investorWeek.heroBox')}</h3>
                  </AccordionSummary>
                  <Divider style={{ margin: '10px 0' }} />
                  <AccordionDetails>
                    <HeroBox
                      form={form}
                      control={control}
                      errors={errors}
                      prevHeaderDetails={prevDetails?.headerDetails}
                    />
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <h3 className={classes.h4Text}>{t('admin.cms.investorAward.basicDetails')}</h3>
                  </AccordionSummary>
                  <Divider style={{ margin: '10px 0' }} />
                  <AccordionDetails>
                    <BasicDetails
                      form={form}
                      control={control}
                      errors={errors}
                    />
                  </AccordionDetails>
                </Accordion>
                <Divider style={{ margin: '10px 0' }} />
                <h3 className={classes.h4Text}>{t('admin.cms.investorAward.prizeTracks')}</h3>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <h3 className={classes.h4Text}>{t('admin.cms.investorAward.videography')}</h3>
                  </AccordionSummary>
                  <Divider style={{ margin: '10px 0' }} />
                  <AccordionDetails>
                    <ContestTerms
                      form={form}
                      control={control}
                      errors={errors}
                      contest="videography"
                    />
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <h3 className={classes.h4Text}>{t('admin.cms.investorAward.photography')}</h3>
                  </AccordionSummary>
                  <Divider style={{ margin: '10px 0' }} />
                  <AccordionDetails>
                    <ContestTerms
                      form={form}
                      control={control}
                      errors={errors}
                      contest="photography"
                    />
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <h3 className={classes.h4Text}>{t('admin.cms.investorAward.drawing')}</h3>
                  </AccordionSummary>
                  <Divider style={{ margin: '10px 0' }} />
                  <AccordionDetails>
                    <ContestTerms
                      form={form}
                      control={control}
                      errors={errors}
                      contest="drawing"
                    />
                  </AccordionDetails>
                </Accordion>
                <Divider style={{ margin: '10px 0' }} />

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <h3 className={classes.h4Text}>{t('admin.cms.investorAward.accordianDetails')}</h3>
                  </AccordionSummary>
                  <Divider style={{ margin: '10px 0' }} />
                  <AccordionDetails>
                    <AcordianDetails
                      form={form}
                      control={control}
                      errors={errors}
                    />
                  </AccordionDetails>
                </Accordion>
                <div className="flex pt-3">
                  <Btn fullWidth={false} type="submit" name={UPDATE_HOME}>
                    {t("common.submit")}
                  </Btn>
                </div>
              </form>
            </Grid>
          </Grid>
        </div>
      }
    />
  )
}

export default InvestorAward