import React, { useEffect, useState } from 'react';
import { Grid, InputAdornment, Paper, TextField } from '@mui/material';
import { Box } from '@mui/system';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useQuery } from 'react-query';
import { USERLIST } from 'Constants/QueriesKeys';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { http } from 'Utils/Http/Http';
import { DataGrid, IconBtnBase, ResourcesList } from 'Components';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';




const styles = {
    tableSize: {
        height: 650,
        width: '100%',
    },
};

// Component goes here
const UserPage = () => {
    const { path } = useRouteMatch();
    const history = useHistory();
    const { t } = useTranslation();

    const [pageTeam, setPageTeam] = useState(1);
    const perPage = 10;
    const spacing = 4
    const [teamMemberList, setTeamMemberList] = useState()
    const [paginator1, setPaginator1] = useState()
    const [searchTeam, setSearchTeam] = useState()



    const teamMemberRender = useQuery([USERLIST, { searchTeam }], () =>
        http.get(`/api/v1/admin/team`, {
            params: {
                page: pageTeam,
                limit: perPage,
                searchString: searchTeam
            },
            isCountOnly: false
        })
            .then(({ data }) => { return (data, setTeamMemberList(data?.data?.records), setPaginator1(data?.data?.totalCount)) }),
    );

    useEffect(() => {
        http.get(`/api/v1/admin/team`, {
            params: {
                page: pageTeam,
                limit: perPage,
                searchString: searchTeam
            },
        }).then(({ data }) => { setTeamMemberList(data?.data?.records); })
    }, [pageTeam]);

    const columns1 = [
        {
            field: 'name',
            headerName: t('admin.users.create.name'),
            minWidth: 150,
            editable: false,
            align: "right",
        },
        {
            field: 'email',
            headerName: t('admin.users.create.email'),
            minWidth: 320,
            editable: false,
            align: "right",
        },
        {
            field: 'profilePic',
            headerName: t('admin.users.create.profilePic'),
            minWidth: 180,
            align: "right",
            editable: false,
            renderCell: (row) => {
                return (
                    <div className="flex items-center">
                        <img src={row?.row?.profilePic} alt=""></img>
                    </div>
                );
            },
        },
        {
            field: 'action',
            headerName: t('common.actions'),
            minWidth: 100,
            editable: false,
            renderCell: (row) => {
                return (
                    <div className="flex items-center">
                        {/* <IconBtnBase
                            onClick={() => {
                                handleUserDetail(row?.row);
                            }}
                            size="large">
                            <DeleteOutlineOutlinedIcon />
                        </IconBtnBase> */}
                        <IconBtnBase
                            onClick={() => history.push(`${path}/details/${row.id}`)}
                            size="large">
                            <VisibilityIcon />
                        </IconBtnBase>
                    </div>
                );
            },
        },
    ];

    var rowData1 = []

    if (teamMemberList?.length > 0) {
        for (let i = 0; i < teamMemberList?.length; i++) {
            rowData1[i] = {
                name: teamMemberList[i]?.name,
                id: teamMemberList[i]?.id,
                email: teamMemberList[i]?.email,
                profilePic: teamMemberList[i]?.profilePic,
            }
        }
    }

    // Handlers

    return (
        <ResourcesList
            headerProps={{
                title: t('admin.users.users'),
                EndSide: (
                    <div className='flex'>
                        <IconBtnBase
                            onClick={() => {
                                history.push(`${path}/create`);
                            }}
                            size="large">
                            <AddIcon />
                        </IconBtnBase>
                        <IconBtnBase
                            onClick={() => { teamMemberRender.refetch() }}
                            size="large">
                            <RefreshIcon />
                        </IconBtnBase>

                    </div>
                ),
            }}
            Section={
                <div className="mt-8">
                    <Box sx={styles.tableSize}>
                        <Grid container spacing={spacing}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    placeholder='Search by Name'
                                    id="outlined-start-adornment"
                                    onChange={(e) => setSearchTeam(e.target.value)}
                                    value={searchTeam}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Paper>
                                    <DataGrid
                                        pagination
                                        rowCount={paginator1}
                                        paginationMode="server"
                                        onPageChange={(newPage) => setPageTeam(newPage + 1)}
                                        loading={teamMemberRender?.isLoading}
                                        rows={rowData1}
                                        columns={columns1}
                                        pageSize={perPage}
                                    />
                                </Paper>
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            }
        />
    );
};

export default UserPage;
