import React from 'react';
import { Divider, Grid, IconButton } from '@mui/material';
import { InputController } from 'Components';
import { EditorController } from 'Components';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const AcordianDetails = ({ control }) => {
  const { t } = useTranslation();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'accordianDetails',
  });

  if (fields?.length < 1) {
    append({
      heading: '',
      description: '',
    });
  }

  return (
    <Grid container spacing={3} style={{ marginBottom: '10px' }}>
      <Grid item xs={12}>
        {fields.map((data, index) => {
          return (
            <Grid container spacing={3} key={data.id}>
              <Grid
                item
                xs={12}
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <h6 className='cms_subheading' style={{ marginBottom: '0px' }}>
                  {t('admin.cms.investorAward.accordianDetails')} {index + 1}
                </h6>
                {fields?.length > 1 ? (
                  <IconButton
                    className='btn_delete'
                    onClick={() => remove(index)}
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <Grid container alignItems='center'>
                  <Grid item xs={12} md={5} lg={4} xl={3}>
                    <h6 className='labelForm' style={{ marginBottom: '0px' }}>
                      {t('admin.cms.about.heading')}
                    </h6>
                  </Grid>
                  <Grid item xs={12} md={7} lg={8} xl={9}>
                    <InputController
                      size='small'
                      control={control}
                      name={`accordianDetails[${index}].heading`}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12} md={5} lg={4} xl={3}>
                    <h6 className='labelForm' style={{ marginBottom: '0px' }}>
                      {t('admin.cms.about.description')}
                    </h6>
                  </Grid>
                  <Grid item xs={12} md={7} lg={8} xl={9}>
                    <EditorController
                      control={control}
                      name={`accordianDetails[${index}].description`}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider style={{ margin: '10px 0' }} />
              </Grid>
            </Grid>
          );
        })}
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid
            item
            xs={8}
            md={8}
            lg={8}
            xl={8}
            onClick={(e) => {
              e.preventDefault();
              append({
                heading: '',
                description: '',
              });
            }}
            className='btn_addMore'
            style={{ cursor: 'pointer' }}
          >
            + {t('admin.cms.home.banner.addMore')}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AcordianDetails;
