import { useQuery } from "react-query";
import { http } from "Utils/Http/Http";
import { PARTICIPATIONLIST } from "Constants/QueriesKeys";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

const ParticipationsPageCount = () => {
  const participationCount = useQuery(
    [PARTICIPATIONLIST],
    () =>
      http
        .get(
          `https://api.mulimgcc.org/api/v1/admin/participations/participations-count`
        )
        .then((response) => response.data.data),
    {
      keepPreviousData: true,
    }
  );

  return (
    <Tabs onChange={(e) => {}} aria-label="basic tabs example" centered>
      <Tab
        label="All"
        value={null}
        icon={
          <span
            style={{
              backgroundColor: "rgb(229, 252, 242)",
              // backgroundColor: "red",
              borderRadius: 99,
              padding: "3px 15px",
            }}
          >
            {participationCount.data?.all ?? "--"}
          </span>
        }
        iconPosition="bottom"
      />
      <Tab
        label="Individuals"
        value={"professional"}
        icon={
          <span
            style={{
              backgroundColor: "rgb(229, 252, 242)",
              // backgroundColor: "red",
              borderRadius: 99,
              padding: "3px 15px",
            }}
          >
            {participationCount.data?.professional ?? "--"}
          </span>
        }
        iconPosition="bottom"
      />
      <Tab
        label="Universities"
        value={"university"}
        icon={
          <span
            style={{
              backgroundColor: "rgb(229, 252, 242)",
              // backgroundColor: "red",
              borderRadius: 99,
              padding: "3px 15px",
            }}
          >
            {participationCount.data?.university ?? "--"}
          </span>
        }
        iconPosition="bottom"
      />
      <Tab
        label="Schools"
        value={"school"}
        icon={
          <span
            style={{
              backgroundColor: "rgb(229, 252, 242)",
              // backgroundColor: "red",
              borderRadius: 99,
              padding: "3px 15px",
            }}
          >
            {participationCount.data?.school ?? "--"}
          </span>
        }
        iconPosition="bottom"
      />
    </Tabs>
  );
};
export default ParticipationsPageCount;
