import React, { useState } from 'react'
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import CloseIcon from '@mui/icons-material/Close';
import { Divider, Grid, IconButton, InputAdornment, SvgIcon } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { Btn, InputController, setProgress } from 'Components';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import yup from 'Utils/Yup';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { http } from 'Utils/Http/Http';

const CHANGE_LINK_BTN = 'CHANGE_LINK_BTN';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "80%",
    maxWidth: "500px",
    borderRadius: "20px",
    bgcolor: 'background.paper',
    // borderRadius:"20px",
    boxShadow: 24,
    p: 4,
};

const useStyle = makeStyles((theme) => ({
    card: {
        padding: "10px",
        boxShadow: "1px 2px 6px 2px rgba(0, 0, 0, 0.2)",
        borderRadius: "8px",
    },
    title: {
        fontSize: "23px",
        color: "rgba(0, 0, 0, 1)",
        fontWeight: "500"
    },
    btn_submit: {
        backgroundColor: "rgba(62, 199, 230, 1)",
        fontSize: "12px",
        color: "rgba(255, 255, 255, 1)",
        '&:hover': {
            color: 'rgba(255, 255, 255, 1)',
            backgroundColor: "rgba(42, 184, 216,1)"
        }
    },
    btn_cancel: {
        backgroundColor: "transparent",
        fontSize: "12px",
        color: "rgba(0, 0, 0, 1)",
        '&:hover': {
            color: 'rgba(0, 0, 0, 1)',
            backgroundColor: "rgba(0, 0, 0, 0.2)"
        }
    }
}));


const schema = yup.object().shape({
    password: yup.string().required(),
    newPassword: yup.string().required(),
    confirmNewPassword: yup.string().oneOf([yup.ref('newPassword')], 'Password and Confirm Password do not match.'),
});


const PasswordChangeModal = ({ open, handleClose }) => {
    const classes = useStyle();
    const { t } = useTranslation();

    const [oldPasswordType, setOldPasswordType] = useState('password')
    const [passwordType, setPasswordType] = useState('password')
    const [confirmType, setConfirmType] = useState('password')

    const form = useForm({
        resolver: yupResolver(schema),
    });


    const { control, handleSubmit} = form;


    const togglePasswordType = (val) => {
        if (val === 'old') {
            if (oldPasswordType === 'password') {
                setOldPasswordType('text')
            } else if (oldPasswordType === 'text') {
                setOldPasswordType('password')
            }
        } else if (val === 'password') {
            if (passwordType === 'password') {
                setPasswordType('text')
            } else if (passwordType === 'text') {
                setPasswordType('password')
            }
        } else if (val === 'confirm') {
            if (confirmType === 'password') {
                setConfirmType('text')
            } else if (confirmType === 'text') {
                setConfirmType('password')
            }
        }
    }

    const handleForgotPassword = async (values) => {
        const data = {
            password: values.password,
            newPassword: values.newPassword
        }
        setProgress(CHANGE_LINK_BTN, true);
        try {
            await http.put(
                'team/change-password',
                { ...data },
                {
                    baseURL: process.env.REACT_APP_BASE_URL,
                },
            ).then(async (res) => {
                handleClose()
                toast.success("password changed successfully")
            }).catch((res) => {
                toast.error(res?.response?.data?.message)
            })
        } catch (res) {
            toast.error(res?.response?.data?.message)
        }
        setProgress(CHANGE_LINK_BTN, false);
    };

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <Grid container justifyContent='space-between' alignItems="center">
                        <Grid item>
                            <h3 className={classes.title}>{t("admin.changePassword.changePassword")}</h3>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Divider fullwidth style={{ margin: "10px 0", backgroundColor: "rgba(0, 0, 0, 0.2)" }} />
                    <form className="space-y-4" onSubmit={handleSubmit(handleForgotPassword)}>
                        <Grid container style={{ marginTop: "10px" }} spacing={5}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <InputController
                                    control={control}
                                    type={oldPasswordType}
                                    name="password"
                                    label={t("password.oldPassword")}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <SvgIcon fontSize="small" color="action" onClick={() => togglePasswordType('old')} style={{ cursor: 'pointer' }}>
                                                    {oldPasswordType === 'password' ?
                                                        <VisibilityOutlinedIcon />
                                                        :
                                                        <VisibilityOffOutlinedIcon />
                                                    }
                                                </SvgIcon>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <InputController
                                    control={control}
                                    type={passwordType}
                                    name="newPassword"
                                    label={t("password.newPassword")}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <SvgIcon fontSize="small" color="action" onClick={() => togglePasswordType('password')} style={{ cursor: 'pointer' }}>
                                                    {passwordType === 'password' ?
                                                        <VisibilityOutlinedIcon />
                                                        :
                                                        <VisibilityOffOutlinedIcon />
                                                    }
                                                </SvgIcon>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <InputController
                                    control={control}
                                    type={confirmType}
                                    name="confirmNewPassword"
                                    label={t("password.confirmNewPassword")}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <SvgIcon fontSize="small" color="action" onClick={() => togglePasswordType('confirm')} style={{ cursor: 'pointer' }}>
                                                    {confirmType === 'password' ?
                                                        <VisibilityOutlinedIcon />
                                                        :
                                                        <VisibilityOffOutlinedIcon />
                                                    }
                                                </SvgIcon>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container style={{ marginTop: '10px' }} justifyContent="space-between" alignItems="center">
                            <Grid item style={{ display: 'flex', justifyContent: 'center' }}>
                                <Btn type="submit" name={CHANGE_LINK_BTN} className={classes.btn_submit}>
                                    {t('common.update')}
                                </Btn>
                            </Grid>
                            <Grid item style={{ display: 'flex', justifyContent: 'center' }}>
                                <Btn className={classes.btn_cancel}>
                                    {t('common.cancel')}
                                </Btn>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Fade>
        </Modal>
    )
}

export default PasswordChangeModal
