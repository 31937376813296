import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { Divider, Grid } from "@mui/material";
import {
  Btn,
  IconBtnBase,
  ResourcesList,
  setProgress,
} from "Components";
import Yup from "Utils/Yup";
import { makeStyles } from "@mui/styles";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import BannerCarousel from "./Components/BannerCarousel";
import AboutDetails from "./Components/AboutDetails";
import RefreshIcon from '@mui/icons-material/Refresh';
import { HOMEDETAIL } from 'Constants/QueriesKeys';
import { useQuery } from "react-query";
import { http } from "Utils/Http/Http";
import { toast } from "react-toastify";


const UPDATE_HOME = "UPDATE_HOME";


const carouselDetailsSchema = Yup.object({
  heading: Yup.string().required("required"),
  headerAlign: Yup.string().required("required"),
  // backgroundImage: Yup.array().required("required"),
  // link: Yup.string().required("required"),
});

const schema = Yup.object({
  aboutMulim: Yup.string().required("required"),
  carouselDetails: Yup
    .array()
    .of(carouselDetailsSchema)
});
const useStyles = makeStyles((theme) => ({
  h3Text: {
    textTransform: 'capitalize',
    fontSize: '24px',
    color: 'rgb(3, 166, 90)',
    fontWeight: '500',
  },
  h4Text: {
    textTransform: 'capitalize',
    fontSize: '18px',
    color: 'rgb(3, 166, 90)',
    fontWeight: '500',
  },
  btn_delete: {
    fontSize: "12px",
    textTransform: "capitalize",
    color: "rgba(255,0,0,0.5)",
    margin: "0 20px",
    '&:hover': {
      color: 'rgba(255,0,0,0.7)',
    }
  },
}))
const HomePage = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [enabled, setEnabled] = useState(true)
  const [prevCarouselDetails, setPrevCarouselDetails] = useState()
  const homePageQuery = useQuery([HOMEDETAIL], () =>
    http.get(`/api/v1/pages`, {}).then(({ data }) => {
      setEnabled(false);
      return (data, setPrevCarouselDetails(data?.data?.homePage?.carouselDetails),
        reset({
          aboutMulim: data?.data?.homePage?.aboutMulim,
          carouselDetails: data?.data?.homePage?.carouselDetails
        })
      )
    }),
    {
      enabled: enabled ? true : false,
    },
  );
  const form = useForm({
    resolver: yupResolver(schema)
  });
  const { control, handleSubmit, reset, errors } = form;


  const handleFormSubmission = async (values) => {
    setProgress(UPDATE_HOME, true);

    var data = {
      homePage: values
    }

    var newImageIndex = []
    let formData = new FormData();
    for (var i = 0; i < values?.carouselDetails?.length; i++) {
      if (typeof (values?.carouselDetails[i]?.backgroundImage) === "object" && values?.carouselDetails[i]?.backgroundImage?.length > 0) {
        formData.append("backgroundImage", values?.carouselDetails[i]?.backgroundImage[0]);
        data['homePage'].carouselDetails[i].backgroundImage = "new"
        newImageIndex.push(i)
      } else {
        data['homePage'].carouselDetails[i].backgroundImage = prevCarouselDetails[i]?.backgroundImage
      }
    }

    if (newImageIndex.length > 0) {
      try {
        await http.post(`/api/v1/helpers/upload-file`, formData)
          .then(async (res) => {
            for (var i = 0; i < newImageIndex?.length; i++) {
              data['homePage'].carouselDetails[newImageIndex[i]].backgroundImage = res.data.data[i].src
            }
            try {
              await http.post(`/api/v1/pages`, data)
                .then(async (res) => {
                  toast.success(t('toast.success.updatedSuccessfully'))
                  homePageQuery.refetch()
                });
            } catch (res) {
              toast.error(res?.response?.data?.message)
            }
          });
      } catch (res) {
        toast.error(res?.response?.data?.message)
        setProgress(UPDATE_HOME, false);
      }
    } else {
      try {
        await http.post(`/api/v1/pages`, data)
          .then(async (res) => {
            toast.success(t('toast.success.updatedSuccessfully'))
            homePageQuery.refetch()
          });
      } catch (res) {
        toast.error(res?.response?.data?.message)
      }
    }
    setProgress(UPDATE_HOME, false);
  };
  return (
    <ResourcesList
      headerProps={{
        title: t('admin.cms.home.homePageDetails'),
        EndSide: (
          <div className='flex'>
            <IconBtnBase
              onClick={() => { homePageQuery.refetch() }}
              size="large">
              <RefreshIcon />
            </IconBtnBase>

          </div>
        ),
      }}
      Section={
        <div className="mt-8">
          <Grid container>
            <Grid item xs={12}>
              <form
                onSubmit={handleSubmit(handleFormSubmission)}
                className="space-y-3 py-5"
              >
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <h3 className={classes.h4Text}>{t('admin.cms.home.banner.carouselDetails')}</h3>
                  </AccordionSummary>
                  <Divider style={{ margin: '10px 0' }} />
                  <AccordionDetails>
                    <BannerCarousel
                      form={form}
                      control={control}
                      errors={errors}
                      prevCarouselDetails={prevCarouselDetails}
                    />
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <h3 className={classes.h4Text}>{t('admin.cms.home.banner.aboutMulim')}</h3>
                  </AccordionSummary>
                  <Divider style={{ margin: '10px 0' }} />
                  <AccordionDetails>
                    <AboutDetails
                      form={form}
                      control={control}
                      errors={errors}
                    />
                  </AccordionDetails>
                </Accordion>
                <div className="flex pt-3">
                  <Btn fullWidth={false} type="submit" name={UPDATE_HOME}>
                    {t("common.submit")}
                  </Btn>
                </div>
              </form>
            </Grid>
          </Grid>
        </div>
      }
    />
  )
}

export default HomePage