import React, { useState } from 'react';
import { Card, CardContent, Typography, Divider, Container, Grid, CircularProgress } from '@mui/material';
import companyLogo from 'Assets/companyLogo.svg';
import { useTranslation } from 'react-i18next';
import { http } from 'Utils/Http/Http';
import { useForm } from 'react-hook-form';
import Yup from 'Utils/Yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Btn, InputController, setProgress } from 'Components';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

const RESET_LINK_BTN = 'RESET_LINK_BTN';

const schema = Yup.object().shape({
    email: Yup.string().email().required(),
});

export default function SendResetLinkPage() {
    const { t } = useTranslation();
    const history = useHistory();
    const [linkSent, setLinkSent] = useState(false)
    const { control, handleSubmit, setError } = useForm({
        resolver: yupResolver(schema),
    });
    const handleForgotPassword = (values) => {
        setProgress(RESET_LINK_BTN, true);
        http.post('/api/v1/admin/session/forget-password', values, {
            baseURL: process.env.REACT_APP_BASE_URL,
        })
            .then(async (res) => {
                setLinkSent(true)
                setTimeout(() => {
                    history.push('/sign-in');
                }, 3000);
            }).catch((res) => {
                toast.error(res.message)
            })
        setProgress(RESET_LINK_BTN, false);
    }

    return (
        <div>
            {linkSent ?
                <div className="min-h-screen bg-gray-100 flex flex-col justify-center items-center px-2 md:px-0">
                    <img className="w-auto mx-auto mb-5" src={companyLogo} alt="logo" style={{ height: "40px" }} />
                    <Container className="max-w-lg">
                        <Card style={{ padding: '25px 20px', borderRadius: '20px', boxShadow: '1px 1px 10px 1px rgba(21,124,15,0.4)' }}>
                            <Grid container style={{ marginTop: "10px", display: 'flex', justifyContent: 'center', flexDirection: "column" }} spacing={2}>
                                <h6 style={{ textAlign: 'center' }}>Reset Link Have Been Sent To Your Email.</h6>
                                <h6 style={{ textAlign: 'center' }}>Please Check Your Email.</h6>
                                <br />
                                <h7 style={{ color: "rgba(0,0,0,0.6)", display: "flex", alignItems: "center", justifyContent: "center" }}><CircularProgress style={{ width: '20px', height: '20px' }} /> &nbsp; Redirecting to Login Page</h7>
                            </Grid>
                        </Card>
                    </Container>
                </div>
                :
                <div className="min-h-screen bg-gray-100 flex flex-col justify-center items-center px-2 md:px-0">
                    <img className="w-auto mx-auto mb-5" src={companyLogo} alt="logo" style={{ height: "40px" }} />
                    <Container className="max-w-lg">
                        <Card style={{ padding: '25px 20px', borderRadius: '20px', boxShadow: '1px 1px 10px 1px rgba(21,124,15,0.4)' }}>
                            <div className="py-5 px-4 text-center">
                                <Typography variant="h5">{t('password.resetPassword')}</Typography>
                            </div>
                            <Divider />
                            <CardContent>
                                <form onSubmit={handleSubmit(handleForgotPassword)}>
                                    <div className="pt-2 pb-8 text-center">
                                        <Typography variant="subtitle" className="text-center">
                                            {t('password.followSteps')}
                                        </Typography>
                                    </div>
                                    <div className="space-y-4">
                                        <div>
                                            <InputController
                                                control={control}
                                                name="email"
                                                label={t('password.emailAddress')}
                                                placeholder={t('password.enterYourEmailAddress')}
                                            />
                                        </div>
                                    </div>
                                    <div className="space-y-4 text-center mt-5">
                                        <Btn type="submit" name={RESET_LINK_BTN} fullWidth={true}>
                                            {t('password.sendResetLink')}
                                        </Btn>
                                    </div>
                                </form>
                            </CardContent>
                        </Card>
                    </Container>
                </div>
            }
        </div>
    );
}
