import React, { useState } from 'react'
import { Divider, Grid, IconButton, MenuItem } from '@mui/material';
import { InputController } from 'Components';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ImageViewModal from 'Components/ImageViewModal/ImageViewModal';
import FileController from 'Components/FormControls/Controllers/FileController';
import FileDownload from 'Components/ViewFile/FileDownload';


const FileDetails = ({ control, form, errors, prevFileDetails }) => {
    const { t } = useTranslation();
    const { fields, append, remove } = useFieldArray({
        control,
        name: "fileDetails"
    });
    if (fields?.length < 1) {
        append({
            fileName: "",
            src: "",
        })
    }

    return (
        <Grid container spacing={3} style={{ marginBottom: "10px" }}>
            <Grid item xs={12}>
                {fields.map((data, index) => {
                    return (
                        <Grid container spacing={3} key={data.id}>
                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <h6
                                    className="cms_subheading"
                                    style={{ marginBottom: "0px" }}
                                >
                                    {t("admin.cms.investorWeek.fileDetails")} {index + 1}
                                </h6>
                                {fields?.length > 1 ?
                                    <IconButton className="btn_delete" onClick={() => remove(index)}><DeleteOutlineIcon /></IconButton>
                                    : null}
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container alignItems="center">
                                    <Grid item xs={12} md={5} lg={4} xl={3}>
                                        <h6
                                            className="labelForm"
                                            style={{ marginBottom: "0px" }}
                                        >
                                            {t("admin.cms.investorWeek.fileName")}
                                        </h6>
                                    </Grid>
                                    <Grid item xs={12} md={7} lg={8} xl={9} style={{ display: "flex" }}>
                                        <InputController
                                            size="small"
                                            control={control}
                                            name={`fileDetails[${index}].fileName`}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container >
                                    <Grid item xs={12} md={5} lg={4} xl={3}>
                                        <h6
                                            className="labelForm"
                                            style={{ marginBottom: "0px" }}
                                        >
                                            {t("admin.cms.investorWeek.selectFile")}
                                        </h6>
                                        {prevFileDetails && prevFileDetails[index]?.src ?
                                            <div style={{ padding: "20px" }}>
                                                <FileDownload file={prevFileDetails[index]?.src} />
                                            </div>
                                            : null
                                        }
                                    </Grid>
                                    <Grid item xs={12} md={7} lg={8} xl={9}>
                                        <FileController
                                            name={`fileDetails[${index}].src`}
                                            filesLimit={1}
                                            acceptedFiles
                                            form={form}
                                            errors={errors}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider style={{ margin: '10px 0' }} />
                            </Grid>
                        </Grid>
                    )
                })}
            </Grid>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={8} md={8} lg={8} xl={8}
                        onClick={(e) => {
                            e.preventDefault();
                            append({
                                fileName: "",
                                src: "",
                            });
                        }}
                        className="btn_addMore"
                        style={{ cursor: 'pointer' }}>
                        + {t("admin.cms.home.banner.addMore")}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default FileDetails