import "./index.css";
import { useTranslation } from "react-i18next";
import { Route, Switch, Redirect } from "react-router-dom";
import { setLocale } from "yup";
import SignInPage from "Pages/Auth/SignInPage";
import { useEffect, useState } from "react";
import { http } from "Utils/Http/Http";
import App from "App";
import { LinearProgress } from "@mui/material";
import { AuthContext } from "Contexts/AuthContext";
import GuardedRoute from "Utils/Guards/GuardedRoute";
import AuthGuard from "Utils/Guards/AuthGuard";
import GuestGuard from "Utils/Guards/GuestGuard";
import SendResetLinkPage from "Pages/Auth/SendResetLinkPage";
import ResetPasswordPage from "Pages/Auth/ResetPasswordPage";
import ParticipationsPageCount from "Pages/WebsitePages/Pages/Participants/ParticipationsPageCount";

setLocale({
  mixed: {
    required: "required",
  },
  string: {
    required: "required",
  },
});

function Entry() {
  const { t } = useTranslation();
  const [isFetchingUserData, setIsFetchingUserData] = useState(false);
  const [auth, setAuth] = useState({
    user: null,
    fetchUserProfile: () => {
      return http
        .get("/admin/get-profile")
        .then((response) => {
          setAuth({ ...auth, user: response.data.data });
        })
        .catch(() => {
          setAuth({ ...auth, user: null });
        });
    },
    // refreshCsrf: () => {
    //     return http.get(`/sanctum/csrf-cookie`, {
    //         baseURL: process.env.REACT_APP_BASE_URL,
    //     });
    // },
  });

  // useEffect(() => {
  //   setIsFetchingUserData(true);
  //   auth.fetchUserProfile().finally(() => setIsFetchingUserData(false));
  //   // auth.refreshCsrf();
  // }, []);

  return (
    <AuthContext.Provider value={auth}>
      {isFetchingUserData ? (
        <div className="flex items-center justify-center h-screen">
          <LinearProgress variant="query" className="w-72" />
        </div>
      ) : (
        <Switch>
          <GuardedRoute
            guards={[GuestGuard]}
            exact
            path="/sign-in"
            component={SignInPage}
          />
          <Route exact path="/forgot-password" component={SendResetLinkPage} />
          <Route
            exact
            path="/forget-password/:emailId/:token"
            component={ResetPasswordPage}
          />
          <Route
            exact
            path="/email_verified/:emailId/:token"
            component={ResetPasswordPage}
          />
          <Route
            exact
            path="/"
            render={() => {
              return (
                //   isFetchingUserData ?
                <Redirect to="/admin/contact-request" />
              );
            }}
          />
          <Route
            exact
            path="/participations/count"
            component={ParticipationsPageCount}
          />
          <GuardedRoute guards={[AuthGuard]} path="/admin" component={App} />
          {/* <Route path="/admin" component={App} /> */}
        </Switch>
      )}
    </AuthContext.Provider>
  );
}

export default Entry;
