import * as React from "react";
import { DataGrid as MuiDataGrid } from "@mui/x-data-grid";
import { IconButton } from "@mui/material";
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import NavigateBeforeRoundedIcon from '@mui/icons-material/NavigateBeforeRounded';

export default function DataGrid({ rows, columns, pageSize, ...otherProps }) {
  return (
    <div dir="ltr">
      <MuiDataGrid
        rows={rows}
        autoHeight
        columns={columns}
        disableColumnFilter
        disableColumnMenu
        disableColumnResize
        disableColumnSelector
        pageSize={pageSize}
        componentsProps={{
          pagination: {
            ActionsComponent: (props) => (
              <div className="flex">
                <IconButton
                  size="small"
                  aria-label="Previous page"
                  onClick={(event) => props.onPageChange(event, props.page - 1)}
                  disabled={props.page === 0}
                >
                  <NavigateBeforeRoundedIcon fontSize="small" />
                </IconButton>
                <IconButton
                  size="small"
                  aria-label="Next page"
                  onClick={(event) => props.onPageChange(event, props.page + 1)}
                  disabled={props.page >= props.pageCount - 1}
                >
                  <NavigateNextRoundedIcon fontSize="small" />
                </IconButton>
              </div>
            ),
          },
        }}
        {...otherProps}
      />
    </div>
  );
}
