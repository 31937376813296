import { yupResolver } from '@hookform/resolvers/yup';
import { InputAdornment, Link, SvgIcon } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import companyLogo from 'Assets/companyLogo.svg';
import Btn from 'Components/Buttons/Btn';
// import useAuth from 'Hooks/UseAuth';
import { LocalStorage } from "Utils/LocalStorage";
import InputController from 'Components/FormControls/Controllers/InputController';
// import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { http } from 'Utils/Http/Http';
import { setProgress } from 'Utils/ProgressUtils/ProgressSvc';
import yup from 'Utils/Yup';
import { toast } from 'react-toastify';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { useState } from 'react';
const storage = new LocalStorage();

const SIGN_IN_BTN = 'SIGN_IN_BTN';

const schema = yup.object().shape({
    email: yup.string().email().required(),
    password: yup.string().required(),
});

export default function SignInPage() {
    const { control, handleSubmit } = useForm({
        resolver: yupResolver(schema),
    });
    const history = useHistory();
    // const auth = useAuth();

    const { t } = useTranslation();

    // const { executeRecaptcha } = useGoogleReCaptcha();
    const [passwordType, setPasswordType] = useState('password')


    const togglePasswordType = (val) => {
        if (val === 'password') {
            if (passwordType === 'password') {
                setPasswordType('text')
            } else if (passwordType === 'text') {
                setPasswordType('password')
            }
        }
    }

    const handleLogin = async (values) => {
        setProgress(SIGN_IN_BTN, true);

        try {
            await http.post(
                '/api/v1/admin/session/login',
                { ...values },
                {
                    baseURL: process.env.REACT_APP_BASE_URL,
                },
            ).then(async (res) => {
                storage.set("user", res?.data?.data);
                storage.set("userToken", res.data.data.loginData.token);
                setProgress(SIGN_IN_BTN, false);
                history.push('/admin')
            }).catch((res) => {
                toast.error(res?.response?.data?.message)
                setProgress(SIGN_IN_BTN, false);
            })

            setProgress(SIGN_IN_BTN, false);
        } catch (error) {
            // handle errors
            setProgress(SIGN_IN_BTN, false);
        }
    };

    return (
        <div className="flex min-h-screen bg-white py-12 justify-center items-center">
            <div style={{ padding: '25px 20px', borderRadius: '20px', boxShadow: '1px 1px 10px 1px rgba(21,124,15,0.4)' }}>
                <div className="w-full max-w-sm mx-auto lg:w-96">
                    <div className="flex flex-col justify-center items-center">
                        <img className="block w-auto" src={companyLogo} alt="CX" style={{ height: "40px" }} />
                    </div>
                    <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
                        {t('signIn.signInToYourAccount')}
                    </h2>
                    <div className="mt-8">
                        <div className="mt-6">
                            <form className="space-y-4" onSubmit={handleSubmit(handleLogin)}>
                                <InputController control={control} name="email" label={t('formLabels.email')} />
                                <InputController
                                    type={passwordType}
                                    control={control}
                                    name="password"
                                    label={t('formLabels.password')}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <SvgIcon fontSize="small" color="action" onClick={() => togglePasswordType('password')} style={{ cursor: 'pointer' }}>
                                                    {passwordType === 'password' ?
                                                        <VisibilityOutlinedIcon />
                                                        :
                                                        <VisibilityOffOutlinedIcon />
                                                    }
                                                </SvgIcon>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <div className="flex items-center justify-between">
                                    <Link component={RouterLink} to="/forgot-password">{t('signIn.forgotYourPassword')}</Link>
                                </div>
                                <Btn type="submit" name={SIGN_IN_BTN}>
                                    {t('signIn.signIn')}
                                </Btn>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
