import React, { useEffect, useState } from 'react';
import { Grid, InputAdornment, Paper, TextField } from '@mui/material';
import { Box } from '@mui/system';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useQuery } from 'react-query';
import { REQUESTLIST } from 'Constants/QueriesKeys';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { http } from 'Utils/Http/Http';
import { DataGrid, IconBtnBase, ResourcesList } from 'Components';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { LocalStorage } from 'Utils/LocalStorage';

const storage = new LocalStorage();

const styles = {
    tableSize: {
        height: 650,
        width: '100%',
    },
};

// Component goes here
const RequestPage = () => {
    const { path } = useRouteMatch();
    const history = useHistory();
    const { t } = useTranslation();

    const [page, setPage] = useState(1);
    const perPage = 10;
    const spacing = 4
    const [requestList, setRequestList] = useState()
    const [paginator1, setPaginator1] = useState()
    const [searchRequest, setSearchRequest] = useState()



    const requestListRender = useQuery([REQUESTLIST, { searchRequest }], () =>
        http.get(`/api/v1/admin/contact-request`, {
            params: {
                page: page,
                limit: perPage,
                searchString: searchRequest
            }
        }).then(({ data }) => { return (data, setRequestList(data?.data?.records), setPaginator1(data?.data?.records.length)) }),
    );

    // useEffect(() => {
    //     http.get(`/api/v1/admin/contact-request`, {
    //         params: {
    //             page: page,
    //             limit: perPage,
    //             searchString: searchRequest
    //         },
    //     }).then(({ data }) => { 
    //         console.log(data?.data?.records)
    //         setRequestList(data?.data?.records)
    //      })
    // }, [page]);

    const columns1 = [
        {
            field: 'name',
            headerName: t('admin.request.name'),
            minWidth: 150,
            editable: false,
        },
        {
            field: 'email',
            headerName: t('admin.request.email'),
            minWidth: 180,
            editable: false,
        },
        {
            field: 'topic',
            headerName: t('admin.request.title'),
            minWidth: 180,
            editable: false,
            renderCell: (row) => {
                return (
                    <div className="flex items-center">
                        <p>{row?.row?.topic}</p>
                    </div>
                );
            },
        },
        {
            field: 'message',
            headerName: t('admin.request.message'),
            minWidth: 180,
            editable: false,
            renderCell: (row) => {
                return (
                    <div className="flex items-center">
                        <p>{row?.row?.message}</p>
                    </div>
                );
            },
        },
        {
            field: 'message',
            headerName: t('admin.request.createdAt'),
            minWidth: 180,
            editable: false,
            renderCell: (row) => {
                return (
                    <div className="flex items-center">
                        <p>{row?.row?.created_at}</p>
                    </div>
                );
            },
        },
        {
            field: 'action',
            headerName: t('common.actions'),
            minWidth: 100,
            editable: false,
            renderCell: (row) => {
                return (
                    <div className="flex items-center">
                        {/* <IconBtnBase
                            onClick={() => {
                                handleUserDetail(row?.row);
                            }}
                            size="large">
                            <DeleteOutlineOutlinedIcon />
                        </IconBtnBase> */}
                        <IconBtnBase
                            onClick={()=>history.push({ pathname:`${path}/details/${row.id}`,state: {detailsData : row?.row}})}
                            size="large">
                            <VisibilityIcon />
                        </IconBtnBase>
                    </div>
                );
            },
        },
    ];

    var rowData1 = []

    if (requestList?.length > 0) {
        for (let i = 0; i < requestList?.length; i++) {
            rowData1[i] = {
                id: requestList[i]?._id,
                name: requestList[i]?.name,
                email: requestList[i]?.email,
                topic:requestList[i]?.topic,
                message: requestList[i]?.message,
                created_at: requestList[i]?.created_at,
            }
        }
    }

    // Handlers

    return (
        <ResourcesList
            headerProps={{
                title: t('navlinks.request'),
                EndSide: (
                    <div className='flex'>
                        <IconBtnBase
                            onClick={() => {requestListRender.refetch()}}
                            size="large">
                            <RefreshIcon />
                        </IconBtnBase>
                    </div>
                ),
            }}
            Section={
                <div className="mt-8">
                    <Box sx={styles.tableSize}>
                        <Grid container spacing={spacing}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    placeholder='Search by Name'
                                    id="outlined-start-adornment"
                                    onChange={(e) => setSearchRequest(e.target.value)}
                                    value={searchRequest}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Paper>
                                    <DataGrid
                                        pagination
                                        rowCount={paginator1}
                                        onPageChange={(newPage) => setPage(newPage + 1)}
                                        loading={requestListRender?.isLoading}
                                        rows={rowData1}
                                        columns={columns1}
                                        pageSize={perPage}
                                    />
                                </Paper>
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            }
        />
    );
};

export default RequestPage;
