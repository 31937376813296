import React from 'react'
import pdfIcon from 'Assets/pdfIcon.svg'
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    link: {
        textDecoration: 'none !important',
        '&:hover': {
            textDecoration: 'none !important'
        }
    },
    root: {
        border: '1px solid rgba(231, 231, 231, 1)',
        borderRadius: '6px',
        padding: '10px',
        flexWrap: 'nowrap',
    }
}))

const FileDownload = ({ file }) => {
    const classes = useStyles();
    var ext = file?.split('.').pop()
    var fileName = file?.split('/').pop()
    return (
        <a className={classes.link} href={file} target="_blank">
            <Grid container spacing={1} alignItems='center' className={classes.root}>
                <Grid item xs={2}>
                    <img src={ext === 'pdf' ? pdfIcon : file} alt="" />
                </Grid>
                <Grid item xs={10}>
                    <h6 style={{ margin: '0', fontSize: '12px', color: 'rgba(77, 79, 92, 1)', overflow: "hidden" }}>{fileName}</h6>
                </Grid>
            </Grid>
        </a>
    )
}

export default FileDownload
