import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import AppLayout from "Layouts/AppLayout";
import SignInPage from "Pages/Auth/SignInPage";
import { NavLinks } from "Constants/NavLinks";
import UserPage from "Pages/User/UserPage";
import CreateUpdateUser from "Pages/User/CreateUpdateUser";
import UserDetailPage from "Pages/User/UserDetailPage";
import ContestPage from "Pages/Contest/ContestPage";
import ContestDetailPage from "Pages/Contest/ContestDetailPage";
import RequestPage from "Pages/Requests/RequestPage";
import RequestDetailPage from "Pages/Requests/RequestDetailPage";
import HomePage from "Pages/WebsitePages/Pages/HomePage/HomePage";
import AboutPage from "Pages/WebsitePages/Pages/AboutPage/AboutPage";
import InvestorWeek from "Pages/WebsitePages/Pages/InvestorWeek/InvestorWeek";
import InvestorAward from "Pages/WebsitePages/Pages/InvestorAward/InvestorAward";
import ParticipationsPage from "Pages/WebsitePages/Pages/Participants/ParticipationsPage";
import ParticipationDetailPage from "Pages/WebsitePages/Pages/Participants/ParticipationDetailPage";

export default function App() {
  let { path } = useRouteMatch();

  return (
    <AppLayout links={NavLinks}>
      <Switch>
        <Route
          exact
          path={`${path}`}
          render={() => {
            return (
              //   isFetchingUserData ?
              <Redirect to={`${path}/contact-request`} />
            );
          }}
        />
        <Route exact path={`${path}/user`} component={UserPage} />
        <Route
          exact
          path={`${path}/user/create`}
          component={CreateUpdateUser}
        />
        <Route
          exact
          path={`${path}/user/edit/:userId`}
          component={CreateUpdateUser}
        />
        <Route
          exact
          path={`${path}/user/details/:userId`}
          component={UserDetailPage}
        />
        {/* <Route exact path={`${path}/contest`} component={ContestPage} />
            <Route exact path={`${path}/contest/details/:contestId`} component={ContestDetailPage} />
        */}
        <Route exact path={`${path}/contact-request`} component={RequestPage} />
        <Route
          exact
          path={`${path}/contact-request/details/:requestId`}
          component={RequestDetailPage}
        />
        <Route exact path={`${path}/cms/home-page`} component={HomePage} />
        <Route exact path={`${path}/cms/about-page`} component={AboutPage} />
        <Route
          exact
          path={`${path}/cms/investor-week-page`}
          component={InvestorWeek}
        />
        <Route
          exact
          path={`${path}/cms/investor-award-page`}
          component={InvestorAward}
        />
        <Route
          exact
          path={`${path}/participations`}
          component={ParticipationsPage}
        />
        <Route
          exact
          path={`${path}/participations/:participationId`}
          component={ParticipationDetailPage}
        />
      </Switch>
    </AppLayout>
  );
}
