import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { Divider, FormControl, FormControlLabel, FormLabel, Grid, IconButton, Radio, RadioGroup, Typography } from "@mui/material";
import {
  Btn,
  InputController,
  setProgress,
  ResourcesList,
  CheckboxGroupController,
} from "Components";
import Yup from "Utils/Yup";
import { useHistory, useParams } from "react-router";
import { http } from "Utils/Http/Http";
import Card from "@mui/material/Card";
import { makeStyles } from "@mui/styles";
import CloseIcon from '@mui/icons-material/Close';
import { toast } from "react-toastify";
import ImageController from "Components/FormControls/Controllers/ImageController";
import { useQuery } from "react-query";
import { USERDETAIL } from "Constants/QueriesKeys";
import { LocalStorage } from 'Utils/LocalStorage';

const storage = new LocalStorage();


const CREATE_PAYOUT_BTN = "CREATE_PAYOUT_BTN";

const schema = Yup.object().shape({
  name: Yup.string().required("required"),
  email: Yup.string().required("required"),
  article: Yup.array().nullable(),
  company: Yup.array().nullable(),
  users: Yup.array().nullable(),
  customerExecutive: Yup.array().nullable(),
  request: Yup.array().nullable(),
});


const useStyles = makeStyles((theme) => ({
  h3Text: {
    textTransform: 'capitalize',
    fontSize: '24px',
    color: 'rgb(3, 166, 90)',
    fontWeight: '500',
  }
}))

function CreateUpdateUser() {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const { userId, userType } = useParams()


  const userDetailQuery = useQuery([USERDETAIL, { userId }], () =>
    userId ?
      typeOfUser === "customer-executive" ?
        http.get(`/customer_executive/${userId}`, {}).then(({ data }) => data)
        :
        http.get(`/team/${userId}`, {}).then(({ data }) => data)
      : null
  );

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: userDetailQuery ?
      {
        name: userDetailQuery?.data?.data?.name,
        email: userDetailQuery?.data?.data?.email,
      } : null
  });


  const { control, handleSubmit, setError, reset, errors } = form;

  const [typeOfUser, setTypeOfUser] = useState(userType)

  const handleFormSubmission = async (values) => {
    setProgress(CREATE_PAYOUT_BTN, true);
    var data = {
      name: values?.name,
      email: values?.email,
    }

    let formData = new FormData();

    for (var i = 0; i < values?.profilePic?.length; i++) {
      formData.append("dv", values?.profilePic[i]);
    }

    if (userId) {
      if (values?.profilePic?.length) {
        try {
          await http.post(`/helpers/upload-image`, formData)
            .then(async (res) => {
              data['profilePic'] = res?.data?.data[0]?.src
              try {
                await http.put(`/api/v1/admin/team/${userId}`, data)
                  .then(async (res) => {
                    history.goBack()
                    toast.success(t('toast.success.addedSuccessfully'))
                  });
                reset({});
              } catch (res) {
                toast.error(res?.response?.data?.message)
              }
            });
        } catch (res) {
          toast.error(res?.response?.data?.message)
        }
      } else {
        data['profilePic'] = userDetailQuery?.data?.data?.profilePic
        try {
          await http.put(`/api/v1/admin/team/${userId}`, data)
            .then(async (res) => {
              history.goBack()
              toast.success(t('toast.success.addedSuccessfully'))
            });
          reset({});
        } catch (res) {
          toast.error(res?.response?.data?.message)
        }
      }
    } else {
      if (values?.profilePic?.length) {
        try {
          await http.post(`/helpers/upload-image`, formData)
            .then(async (res) => {
              data['profilePic'] = res?.data?.data[0]?.src
              try {
                await http.post(`/api/v1/admin/team`, data)
                  .then(async (res) => {
                    history.push('/admin/user');
                    toast.success(t('toast.success.addedSuccessfully'))
                  });
                reset({});
              } catch (res) {
                toast.error(res?.response?.data?.message)
              }
            });
        } catch (res) {
          toast.error(res?.response?.data?.message)
        }
      } else {
        try {
          await http.post(`/api/v1/admin/team`, data)
            .then(async (res) => {
              history.push('/admin/user');
              toast.success(t('toast.success.addedSuccessfully'))
            });
          reset({});
        } catch (res) {
          toast.error(res?.response?.data?.message)
        }
      }
    }
    setProgress(CREATE_PAYOUT_BTN, false);
  };

  return (
    <ResourcesList
      BeforeHeader={
        <>
          <Typography variant="h6" color="textPrimary">
            {t('admin.users.users')}
          </Typography>
        </>
      }
      Section={
        <Card variant="outlined" className="p-5">
          <Grid container justifyContent='space-between'>
            <Grid item>
              <h3 className={classes.h3Text}>{userId ? t("common.updateDetails") : t("common.createNew")}</h3>
            </Grid>
            <Grid item>
              <Grid container>
                <Grid item>
                  <IconButton onClick={() => history.goBack()} ><CloseIcon /></IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider style={{ margin: '10px 0' }} />
          <form
            onSubmit={handleSubmit(handleFormSubmission)}
            className="space-y-3 py-5 col-span-4 w-6/12"
          >
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <InputController
                  size="small"
                  control={control}
                  name="name"
                  label={t("admin.users.create.name")}
                />
              </Grid>
              <Grid item xs={12}>
                <InputController
                  size="small"
                  control={control}
                  name="email"
                  label={t("admin.users.create.email")}
                />
              </Grid>
              <Grid item xs={12}>
                <h6 className="labelForm" style={{ marginBottom: '15px' }}>{t("admin.users.create.profilePic")}</h6>
                <ImageController
                  name="profilePic"
                  filesLimit={1}
                  acceptedFiles
                  form={form}
                  errors={errors}
                />
                {userDetailQuery?.data?.data?.profilePic ?
                  <img src={userDetailQuery?.data?.data?.profilePic} alt="" style={{ height: "100px" }} />
                  : null}
              </Grid>

              <Grid item xs={12}>
                <div className="flex pt-3">
                  <Btn fullWidth={false} type="submit" name={CREATE_PAYOUT_BTN}>
                    {t("common.submit")}
                  </Btn>
                </div>
              </Grid>
            </Grid>
          </form>
        </Card>
      }
    />
  );
}

export default CreateUpdateUser;
