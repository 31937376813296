// export const FUNDS = 'FUNDS';
// export const ACCOUNTS = 'ACCOUNTS';
// export const ACCOUNTDETAIL = 'ACCOUNTDETAIL';

export const CONTESTLIST='CONTESTLIST'
export const CONTESTETAILS='CONTESTETAILS'

export const REQUESTLIST='REQUESTLIST'
export const REQUESTDETAILS='REQUESTDETAILS'

export const USERDETAIL='USERDETAIL'
export const USERLIST='USERLIST'

export const HOMEDETAIL="HOMEDETAIL"

export const ABOUTDETAIL="ABOUTDETAIL"

export const PARTICIPATIONLIST='PARTICIPATIONLIST'
export const PARTICIPATIONDETAIL='PARTICIPATIONDETAIL'