import React from 'react'
import { Grid } from '@mui/material';
import { EditorController, InputController } from 'Components';
import { useTranslation } from 'react-i18next';


const ContestTerms = ({ control,contest }) => {
    const { t } = useTranslation();


    return (
        <Grid container spacing={3} style={{ marginBottom: "10px" }}>
            <Grid item xs={12}>
                <Grid container alignItems="center">
                    <Grid item xs={12} md={5} lg={4} xl={3}>
                        <h6
                            className="labelForm"
                            style={{ marginBottom: "0px" }}
                        >
                            {t("admin.cms.investorAward.policyHeading")}
                        </h6>
                    </Grid>
                    <Grid item xs={12} md={7} lg={8} xl={9}>
                        <InputController
                            size="small"
                            control={control}
                            name={`${contest}.policy.title`}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container >
                    <Grid item xs={12} md={5} lg={4} xl={3}>
                        <h6
                            className="labelForm"
                            style={{ marginBottom: "0px" }}
                        >
                            {t("admin.cms.investorAward.policyTerms")}
                        </h6>
                    </Grid>
                    <Grid item xs={12} md={7} lg={8} xl={9}>
                        <EditorController
                            control={control}
                            name={`${contest}.policy.listText`}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container alignItems="center">
                    <Grid item xs={12} md={5} lg={4} xl={3}>
                        <h6
                            className="labelForm"
                            style={{ marginBottom: "0px" }}
                        >
                            {t("admin.cms.investorAward.criteriaHeading")}
                        </h6>
                    </Grid>
                    <Grid item xs={12} md={7} lg={8} xl={9}>
                        <InputController
                            size="small"
                            control={control}
                            name={`${contest}.criteria.title`}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={12} md={5} lg={4} xl={3}>
                        <h6
                            className="labelForm"
                            style={{ marginBottom: "0px" }}
                        >
                            {t("admin.cms.investorAward.criteriaTerms")}
                        </h6>
                    </Grid>
                    <Grid item xs={12} md={7} lg={8} xl={9}>
                         <EditorController
                            control={control}
                            name={`${contest}.criteria.listText`}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ContestTerms