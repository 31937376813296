import { Grid } from '@mui/material';
import { EditorController } from 'Components';
import React from 'react'
import { useTranslation } from 'react-i18next';

const AboutDetails = ({ control, form, errors }) => {
    const { t } = useTranslation();

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <h6
                    className="labelForm"
                    style={{ marginBottom: "0px" }}
                >
                    {t("admin.cms.home.banner.aboutMulim")}
                </h6>
            </Grid>
            <Grid item xs={12}>
                <EditorController
                    control={control}
                    name={`aboutMulim`}
                />
            </Grid>
        </Grid>
    )
}

export default AboutDetails