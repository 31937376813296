import React, { useState } from 'react'
import { Divider, Grid, IconButton, MenuItem } from '@mui/material';
import { InputController } from 'Components';
import ImageController from 'Components/FormControls/Controllers/ImageController';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ImageViewModal from 'Components/ImageViewModal/ImageViewModal';


const BannerCarousel = ({ control, form, errors, prevCarouselDetails }) => {
    const { t } = useTranslation();
    const [modalOpen, setModalOpen] = useState(false);
    const [prevImage, setPrevImage] = useState();

    const { fields, append, remove } = useFieldArray({
        control,
        name: "carouselDetails"
    });

    if (fields?.length < 1) {
        append({
            heading: "",
            headingColor: "#000000",
            headerAlign: "",
            backgroundImage: "",
            link: ""
        })
    }

    const flexArray = [
        {
            title: 'flex-start',
            value: 'flex-start',
        },
        {
            title: 'center',
            value: 'center',
        },
        {
            title: 'flex-end',
            value: 'flex-end',
        },
    ];
    return (
        <Grid container spacing={3} style={{ marginBottom: "10px" }}>
            <Grid item xs={12}>
                {fields.map((data, index) => {
                    return (
                        <Grid container spacing={3} key={data.id}>
                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <h6
                                    className="cms_subheading"
                                    style={{ marginBottom: "0px" }}
                                >
                                    {t("admin.cms.home.banner.carouselDetails")} {index + 1}
                                </h6>
                                {fields?.length > 1 ?
                                    <IconButton className="btn_delete" onClick={() => remove(index)}><DeleteOutlineIcon /></IconButton>
                                    : null}
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container alignItems="center">
                                    <Grid item xs={12} md={5} lg={4} xl={3}>
                                        <h6
                                            className="labelForm"
                                            style={{ marginBottom: "0px" }}
                                        >
                                            {t("admin.cms.home.banner.headingText")}
                                        </h6>
                                    </Grid>
                                    <Grid item xs={12} md={7} lg={8} xl={9} style={{ display: "flex" }}>
                                        <InputController
                                            size="small"
                                            control={control}
                                            name={`carouselDetails[${index}].heading`}
                                        />
                                        <InputController
                                            size="small"
                                            type="color"
                                            control={control}
                                            defaultValue="#000000"
                                            style={{ width: "50px", marginRight: "10px" }}
                                            fullWidth={false}
                                            name={`carouselDetails[${index}].headingColor`}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container alignItems="center">
                                    <Grid item xs={12} md={5} lg={4} xl={3}>
                                        <h6
                                            className="labelForm"
                                            style={{ marginBottom: "0px" }}
                                        >
                                            {t("admin.cms.home.banner.headerAlign")}
                                        </h6>
                                    </Grid>
                                    <Grid item xs={12} md={7} lg={8} xl={9}>
                                        <InputController
                                            size="small"
                                            select
                                            control={control}
                                            name={`carouselDetails[${index}].headerAlign`}
                                        >
                                            {flexArray.map((option) => (
                                                <MenuItem value={option.value}>{option.title}</MenuItem>
                                            ))}
                                        </InputController>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container >
                                    <Grid item xs={12} md={5} lg={4} xl={3}>
                                        <h6
                                            className="labelForm"
                                            style={{ marginBottom: "0px" }}
                                        >
                                            {t("admin.cms.home.banner.backgroundImage")}
                                        </h6>
                                        {prevCarouselDetails && prevCarouselDetails[index]?.backgroundImage ?
                                            <div style={{ padding: "20px" }}>
                                                <img src={prevCarouselDetails[index]?.backgroundImage} alt="prev image" style={{ cursor: "pointer" }} onClick={() => { setModalOpen(true); setPrevImage(prevCarouselDetails[index]?.backgroundImage) }} />
                                            </div>
                                            : null
                                        }
                                    </Grid>
                                    <Grid item xs={12} md={7} lg={8} xl={9}>
                                        <ImageController
                                            name={`carouselDetails[${index}].backgroundImage`}
                                            filesLimit={1}
                                            acceptedFiles
                                            form={form}
                                            errors={errors}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container alignItems="center">
                                    <Grid item xs={12} md={5} lg={4} xl={3}>
                                        <h6
                                            className="labelForm"
                                            style={{ marginBottom: "0px" }}
                                        >
                                            {t("admin.cms.home.banner.url")}
                                        </h6>
                                    </Grid>
                                    <Grid item xs={12} md={7} lg={8} xl={9}>
                                        <InputController
                                            size="small"
                                            control={control}
                                            name={`carouselDetails[${index}].link`}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider style={{ margin: '10px 0' }} />
                            </Grid>
                        </Grid>
                    )
                })}
            </Grid>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={8} md={8} lg={8} xl={8}
                        onClick={(e) => {
                            e.preventDefault();
                            append({
                                heading: "",
                                headingColor: "#000000",
                                headerAlign: "",
                                backgroundImage: "",
                                link: ""
                            });
                        }}
                        className="btn_addMore"
                        style={{ cursor: 'pointer' }}>
                        + {t("admin.cms.home.banner.addMore")}
                    </Grid>
                </Grid>
            </Grid>
            <ImageViewModal open={modalOpen} handleClose={() => setModalOpen(false)} item={prevImage} />
        </Grid>
    )
}

export default BannerCarousel