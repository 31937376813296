import React, { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { CircularProgress, Divider, Grid, Hidden, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useQuery } from 'react-query';
import { USERDETAIL } from 'Constants/QueriesKeys';
import { http } from 'Utils/Http/Http';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import { ResourcesList } from 'Components';
import Card from '@mui/material/Card';
import { Box } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { makeStyles } from '@mui/styles';
import { useHistory } from "react-router-dom";
import ConfirmationDialog from 'Components/Dialogs/ConfirmationDialog';
import { toast } from 'react-toastify';
import DoneIcon from '@mui/icons-material/Done';
import profilePic from "Assets/profilePic.png"
import { LocalStorage } from 'Utils/LocalStorage';


const storage = new LocalStorage();


const styles = {
    tableSize: {
        minHeight: 'calc(100vh - 250px)',
        width: '100%',
    },
    h5Text: {
        textTransform: 'capitalize',
        color: 'red',
    }
};

const useStyles = makeStyles((theme) => ({
    h5Text: {
        textTransform: 'capitalize',
        fontSize: '18px',
        color: 'rgba(0,0,0,1)'
    },
    h3Text: {
        textTransform: 'capitalize',
        fontSize: '24px',
        color: 'rgb(3, 166, 90)',
        fontWeight: '500',
    },
    pText: {
        textTransform: 'capitalize',
        fontSize: '18px',
        color: 'rgba(0,0,0,0.8)'
    },
    btnClose: {
        color: 'rgba(255,0,0,0.5)',
        '&:hover': {
            color: 'rgba(255,0,0,0.7)'
        }
    },
    btnEdit: {
        color: 'rgba(3, 166, 90,0.5)',
        '&:hover': {
            color: 'rgba(3, 166, 90,0.7)'
        }
    }
}))

// Component goes here
const UserDetailPage = () => {
    const spacing = 4
    const classes = useStyles();
    const history = useHistory();
    const { userId } = useParams();
    const { t } = useTranslation();
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [detailsData, setDetailsData] = useState()


    const userDetailQuery = useQuery([USERDETAIL, { userId }], () =>
        http.get(`/api/v1/admin/team/${userId}`, {})
        .then(({ data }) => { return (data, setDetailsData(data?.data?.records), console.log(data?.data?.records)) }),
    );

    const handleSoftDelete = async () => {
        try {
            await http.delete(`/api/v1/admin/team/${userId}`, {})
                .then(async (res) => {
                    toast.success(t('toast.success.deletedSuccessfully'))
                    history.push('/admin/users');
                });
        } catch (res) {
            toast.error(res?.response?.data?.message)
        }
    };

    return (
        <>
            <Grid item xs={12}>
                <ResourcesList
                    BeforeHeader={
                        <Breadcrumbs className="mb-4">
                            <Link component={NavLink} to="/admin/users">
                                {t("admin.users.users")}
                            </Link>
                            <Typography color="textPrimary">#{userId}</Typography>
                        </Breadcrumbs>
                    }
                    Section={
                        <div className="mt-8">
                            <Box sx={styles.tableSize}>
                                <Paper>
                                    <Card variant="outlined">
                                        <Grid container justifyContent="space-between" style={{ padding: '10px 20px 0px' }}>
                                            <Grid item>
                                                <h3 className={classes.h3Text}>{t("admin.users.userDetails")}</h3>
                                            </Grid>
                                            <Grid item>
                                                <Grid container>
                                                    <Grid item>
                                                        <IconButton className={classes.btnEdit} onClick={() => history.push(`/admin/user/edit/${userId}`)} ><BorderColorOutlinedIcon /></IconButton>
                                                    </Grid>
                                                    <Grid item>
                                                        <IconButton className={classes.btnClose} onClick={() => setOpenDeleteModal(true)} ><DeleteOutlineOutlinedIcon /></IconButton>
                                                    </Grid>
                                                    <Grid item>
                                                        <IconButton onClick={() => history.goBack()} ><CloseIcon /></IconButton>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider style={{ margin: '10px 0' }} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container style={{ padding: '0px 20px' }}>
                                                <Hidden only={["md", "lg", "xl"]}>
                                                    <Grid item xs={12}>
                                                        <img src={profilePic} alt="" style={{ height: "100px" }} />
                                                    </Grid>
                                                </Hidden>
                                                <Grid item xs={10}>
                                                    <Grid container spacing={spacing}>
                                                        <Grid item xs={12}>
                                                            <Grid container>
                                                                <Grid item xs={2}>
                                                                    <h5 className={classes.h5Text}>{t("admin.users.create.name")}:</h5>
                                                                </Grid>
                                                                <Grid item xs={10}>
                                                                    <p className={classes.pText}>{detailsData?.name}</p>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Grid container>
                                                                <Grid item xs={2}>
                                                                    <h5 className={classes.h5Text}>{t('admin.users.create.email')} :</h5>
                                                                </Grid>
                                                                <Grid item xs={10}>
                                                                    <p className={classes.pText}>
                                                                        {detailsData?.email}
                                                                    </p>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Hidden only={["xs", "sm"]}>
                                                    <Grid item xs={2}>
                                                        <img src={detailsData?.profilePic} style={{ height: "100px" }} alt="" />
                                                    </Grid>
                                                </Hidden>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Paper>
                            </Box>
                            <ConfirmationDialog isOpen={openDeleteModal} title="Delete" text="Are you sure you want to delete this user?" onReject={() => setOpenDeleteModal(false)} onResolve={handleSoftDelete} />
                        </div>
                    }
                />
            </Grid>
        </>
    );
};

export default UserDetailPage;
