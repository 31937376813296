import GroupIcon from '@mui/icons-material/Group';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import HomeIcon from '@mui/icons-material/Home';
import AboutIcon from '@mui/icons-material/Info';
import FlagIcon from '@mui/icons-material/Flag';
import EmailIcon from '@mui/icons-material/Email';
import PeopleIcon from '@mui/icons-material/People'
export const NavLinks = [
    { name: 'User', path: '/admin/user', current: true, Icon: GroupIcon },
    { name: 'Request', path: '/admin/contact-request', current: true, Icon: EmailIcon },
    { name: 'Home', path: '/admin/cms/home-page', current: true, Icon: HomeIcon },
    { name: 'About', path: '/admin/cms/about-page', current: true, Icon: AboutIcon },
    { name: 'Investor Week', path: '/admin/cms/investor-week-page', current: true, Icon: FlagIcon },
    { name: 'Investor Award', path: '/admin/cms/investor-award-page', current: true, Icon: EmojiEventsIcon },
    {name:  'Participations',path:'/admin/participations', current: true, Icon: PeopleIcon}
];
