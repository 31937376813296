import { Card, Divider, Grid, Hidden, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { ResourcesList } from "Components";
import { PARTICIPATIONDETAIL } from "Constants/QueriesKeys";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useLocation, useParams } from "react-router-dom";
import { http } from "Utils/Http/Http";
import { makeStyles } from "@mui/styles";
import VideoPlaceholder from "../../../../Assets/video-placeholder.png";
import FilePlaceholder from "../../../../Assets/file-placeholder.png";

const styles = {
  tableSize: {
    minHeight: "calc(100vh - 250px)",
    width: "100%",
  },
  h5Text: {
    textTransform: "capitalize",
    color: "red",
  },
};

const useStyles = makeStyles((theme) => ({
  h5Text: {
    textTransform: "capitalize",
    fontSize: "18px",
    color: "rgba(0,0,0,1)",
  },
  h3Text: {
    textTransform: "capitalize",
    fontSize: "24px",
    color: "rgb(3, 166, 90)",
    fontWeight: "500",
  },
  pText: {
    textTransform: "capitalize",
    fontSize: "18px",
    color: "rgba(0,0,0,0.8)",
  },
  aText: {
    color: "rgb(0, 0, 255)",
    fontWeight: "500",
  },
}));

function ParticipationDetailPage() {
  const { participationId } = useParams();
  const location = useLocation();
  const spacing = 3;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Grid item xs={12}>
        <ResourcesList
          BeforeHeader={
            <Typography color="textPrimary">#{participationId}</Typography>
          }
          Section={
            <div className="mt-8">
              <Box sx={styles.tableSize}>
                <Paper>
                  <Card variant="outlined">
                    <Grid
                      container
                      justifyContent="space-between"
                      style={{ padding: "10px 20px 0px" }}
                    >
                      <Grid item>
                        <h3 className={classes.h3Text}>
                          {t("participatians Details")}
                        </h3>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider style={{ margin: "10px 0" }} />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container style={{ padding: "0px 20px" }}>
                        <Hidden only={["md", "lg", "xl"]}></Hidden>
                        <Grid item xs={10}>
                          <Grid container spacing={spacing}>
                            <Grid item xs={12}>
                              <Grid container>
                                <Grid item xs={2}>
                                  <h5 className={classes.h5Text}>
                                    {t("full name")}:
                                  </h5>
                                </Grid>
                                <Grid item xs={10}>
                                  <p className={classes.pText}>
                                    {location?.state?.detailsData?.first_name}{" "}
                                    {location?.state?.detailsData
                                      ?.father_name == "NO DATA"
                                      ? ""
                                      : location?.state?.detailsData
                                          ?.father_name}{" "}
                                    {location?.state?.detailsData?.last_name ==
                                    "NO DATA"
                                      ? ""
                                      : location?.state?.detailsData?.last_name}
                                  </p>
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid item xs={12}>
                              <Grid container>
                                <Grid item xs={2}>
                                  <h5 className={classes.h5Text}>
                                    {t("email")}:
                                  </h5>
                                </Grid>
                                <Grid item xs={10}>
                                  <p className={classes.pText}>
                                    {location?.state?.detailsData?.email}
                                  </p>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container>
                                <Grid item xs={2}>
                                  <h5 className={classes.h5Text}>
                                    {t("age")}:
                                  </h5>
                                </Grid>
                                <Grid item xs={10}>
                                  <p className={classes.pText}>
                                    {location?.state?.detailsData?.age}{" "}
                                  </p>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container>
                                <Grid item xs={2}>
                                  <h5 className={classes.h5Text}>
                                    {t("track")}:
                                  </h5>
                                </Grid>
                                <Grid item xs={10}>
                                  <p className={classes.pText}>
                                    {location?.state?.detailsData?.track}{" "}
                                  </p>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container>
                                <Grid item xs={2}>
                                  <h5 className={classes.h5Text}>
                                    {t("Category")}:
                                  </h5>
                                </Grid>
                                <Grid item xs={10}>
                                  <p className={classes.pText}>
                                    {
                                      location?.state?.detailsData
                                        ?.awardParticipatCategories
                                    }{" "}
                                  </p>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container>
                                <Grid item xs={2}>
                                  <h5 className={classes.h5Text}>
                                    {t("phone")}:
                                  </h5>
                                </Grid>
                                <Grid item xs={10}>
                                  <p className={classes.pText}>
                                    {location?.state?.detailsData?.phone}{" "}
                                  </p>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container>
                                <Grid item xs={2}>
                                  <h5 className={classes.h5Text}>
                                    {t("gender")}:
                                  </h5>
                                </Grid>
                                <Grid item xs={10}>
                                  <p className={classes.pText}>
                                    {location?.state?.detailsData?.gender}{" "}
                                  </p>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container>
                                <Grid item xs={2}>
                                  <h5 className={classes.h5Text}>
                                    {t("country/city")}:
                                  </h5>
                                </Grid>
                                <Grid item xs={10}>
                                  <p className={classes.pText}>
                                    {location?.state?.detailsData?.country}/
                                    {location?.state?.detailsData?.city}{" "}
                                  </p>
                                </Grid>
                              </Grid>
                            </Grid>
                            {/* <Grid item xs={12}>
                              <Grid container>
                                <Grid item xs={2}>
                                  <h5 className={classes.h5Text}>{t("district/street")}:</h5>
                                </Grid>
                                <Grid item xs={10}>
                                  <p className={classes.pText}>
                                    {location?.state?.detailsData?.district} / {location?.state?.detailsData?.street}
                                  </p>
                                </Grid>
                              </Grid>
                            </Grid> */}
                            <Grid item xs={12}>
                              <Grid container>
                                <Grid item xs={2}>
                                  <h5 className={classes.h5Text}>
                                    {t("files link")}:
                                  </h5>
                                </Grid>
                                <Grid item xs={10}>
                                  {location?.state?.detailsData?.file_link ? (
                                    <a
                                      className={classes.aText}
                                      target="blank"
                                      href={
                                        location?.state?.detailsData?.file_link
                                      }
                                    >
                                      file participation
                                    </a>
                                  ) : (
                                    <p className={classes.aText}>----</p>
                                  )}
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container>
                                <Grid item xs={2}>
                                  <h5 className={classes.h5Text}>
                                    {t("files")}:
                                  </h5>
                                </Grid>
                                {location?.state?.detailsData?.files.map(
                                  (photo, index) =>
                                    /\.(jpg|bmp|png|gif|jpeg)$/.test(
                                      photo.name
                                    ) ? (
                                      <a
                                        key={index}
                                        href={photo.url}
                                        target="_blank"
                                      >
                                        <img
                                          src={photo.url}
                                          style={{
                                            height: "150px",
                                            margin: "5px",
                                          }}
                                          alt=""
                                        />
                                      </a>
                                    ) : (
                                      location?.state?.detailsData?.files.map(
                                        (photo) =>
                                          /\.(pdf|doc|docx)$/.test(
                                            photo.name
                                          ) ? (
                                            <a
                                              key={index}
                                              href={photo.url}
                                              target="_blank"
                                            >
                                              <img
                                                src={FilePlaceholder}
                                                style={{
                                                  height: "100px",
                                                  width: "150px",
                                                  margin: "5px",
                                                }}
                                                alt=""
                                              />
                                            </a>
                                          ) : (
                                            <a
                                              key={index}
                                              href={photo.url}
                                              target="_blank"
                                            >
                                              <img
                                                src={VideoPlaceholder}
                                                style={{
                                                  height: "100px",
                                                  width: "150px",
                                                  margin: "5px",
                                                }}
                                                alt=""
                                              />
                                            </a>
                                          )
                                      )
                                    )
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Card>
                </Paper>
              </Box>
            </div>
          }
        />
      </Grid>
    </>
  );
}

export default ParticipationDetailPage;
