import React from 'react'
import { Divider, Grid, IconButton } from '@mui/material';
import { InputController } from 'Components';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';


const BasicDetails = ({ control }) => {
    const { t } = useTranslation();

    return (
        <Grid container spacing={3} style={{ marginBottom: "10px" }}>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={12} md={5} lg={4} xl={3}>
                        <h6
                            className="labelForm"
                            style={{ marginBottom: "0px" }}
                        >
                            {t("admin.cms.investorAward.aboutAward")}
                        </h6>
                    </Grid>
                    <Grid item xs={12} md={7} lg={8} xl={9}>
                        <InputController
                            multiline
                            rows={4}
                            size="small"
                            control={control}
                            name={`aboutAward`}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container >
                    <Grid item xs={12} md={5} lg={4} xl={3}>
                        <h6
                            className="labelForm"
                            style={{ marginBottom: "0px" }}
                        >
                            {t("admin.cms.investorAward.targetedAudience")}
                        </h6>
                    </Grid>
                    <Grid item xs={12} md={7} lg={8} xl={9}>
                        <InputController
                            multiline
                            rows={4}
                            size="small"
                            control={control}
                            name={`targetedAudience`}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default BasicDetails